/*global chrome*/
import React, { useState, useEffect } from "react";
import useGlobal from "../GlobalState/Store/Store";
import axios from "axios";
import setAuthToken from "../Utils/setAuthToken.js";
//import LaunchGoogleAuth from "../Authentication/OAuth.js";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";
import CssBaseline from "@mui/material/CssBaseline";
import DetectClientInfo from "../Utils/DetectClientInfo.js";

import { Container, Button, CircularProgress, Link, Grid, Typography, TextField, Box, Divider } from "@mui/material";

import { useNavigate, useLocation, Routes, Route } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";

import GoogleRedirect from "./GoogleRedirect.js";

import Logo from "../Assets/logo@150.png";
import GoogleMark from "../Assets/GoogleMark.png";

function Copyright() {
  return (
    <Typography align="center" color="textSecondary" variant="body2">
      {"Copyright © "}
      <Link color="inherit" href="https://partizion.io/terms" target="_blank" underline="hover">
        Partizion
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

// eslint-disable-next-line react/no-multi-comp
export default function Login() {
  const [globalState, globalActions] = useGlobal();
  let navigate = useNavigate();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const [cookies, setCookie, removeCookie] = useCookies(["partizion_token", "partizion_refreshToken"]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const showReset = globalState.appLocation ? false : true;
  //console.log(globalState.appLocation)
  const [req, setReq] = useState({
    loading: false,
    isError: false,
    error: null,
  });

  const { browserName, majorVersion, OSName } = DetectClientInfo();
  const [currentDevice, setCurrentDevice] = useState({
    os: OSName,
    browser: browserName,
  });
  const cookieMaxAge = 60 * 60 * 24 * 60; // 60 days in seconds

  // console.log("REDIRECT TO LOGIN. PROPS:")
  // console.log({history, location})
  // console.log(props)

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  //LOGIN
  const handleLogin = async (event) => {
    event.preventDefault();
    setReq({
      loading: true,
      isError: false,
      error: null,
    });

    const typeString = browserName + " Web app";
    const userData = {
      email: email,
      password: password,
      browser: browserName,
      operatingSystem: OSName,
      appType: typeString,
    };

    const baseURL = process.env.NODE_ENV !== "development" ? "https://www.api.partizion.io/api/v2" : "http://localhost:5000/api/v2";

    try {
      const res = await axios({
        method: "post",
        url: `${baseURL}/auth/login`,
        data: userData,
      });

      if (!res?.data) {
        throw new Error("Invalid response from server");
      }

      const { partizion_token, partizion_refreshToken } = res.data;

      // Handle successful login
      setReq({
        loading: false,
        isError: false,
        error: null,
      });

      let isProd = process.env.NODE_ENV !== "development";
      setCookie("partizion_refreshToken", partizion_refreshToken, {
        path: "/",
        sameSite: "lax",
        maxAge: cookieMaxAge,
        secure: isProd,
      });
      setCookie("partizion_token", partizion_token, {
        path: "/",
        sameSite: "lax",
        maxAge: cookieMaxAge,
        secure: isProd,
      });
      setAuthToken(partizion_token);

      // Handle user data and navigation
      const decoded = jwt_decode(partizion_token);
      globalActions.setUser(decoded);
      globalActions.LogInOut(true);
      globalActions.validatePrivateRoutePrivilege(true);

      if (location?.state?.referrer && location.state.referrer !== "/login") {
        navigate(location.state.referrer);
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log({ err });
      setReq({
        loading: false,
        isError: true,
        error: {
          code: "LOGIN_ERROR",
          message:
            err.response?.data?.message || (err.response?.status !== 500 ? "Invalid credentials" : "Unable to connect to server. Please try again later."),
        },
      });
    }
  };

  const hanldeKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;
    //console.log("key " + charCode + " pressed")
    if (charCode == 13) {
      //console.log("Enter key pressed")
      //if enter is pressed login
      handleLogin(event);
    }
  };

  // async function GoogleAuth(){
  //  const googleAuth = await LaunchGoogleAuth()
  //   if(googleAuth){
  //     console.log("response is:")
  //     console.log(googleAuth)
  //   }
  // }

  //GOOGLE AUTH
  const [googleUrl, setGoogleUrl] = useState(null);
  const [tempCode, setTempCode] = useState("");
  const [shouldSignup, setShouldSignup] = useState(false);
  useEffect(() => {
    //on render get the google login URL
    const baseURL = process.env.NODE_ENV !== "development" ? "https://www.api.partizion.io/api/v2" : "http://localhost:5000/api/v2";

    axios
      .get(`${baseURL}/auth/google/url`)
      .then((res) => {
        //console.log(res)
        setGoogleUrl(res.data.url);
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line react/display-name
    return () => null;
  }, []);

  return (
    <Routes>
      <Route
        element={
          <>
            <Link style={{ position: "absolute", left: 20, top: 20, zIndex: 999 }} to="/" underline="hover">
              <img src={Logo} style={{ marginBottom: "20px" }} />
            </Link>
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                position: "relative",
                width: "350px",
                height: "500px",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "32px, 32px, 0px, 32px",
              }}
            >
              <CssBaseline />
              <Box
                elevation={0}
                sx={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 6,
                  width: 425,
                  backgroundColor: "transparent", //'background.paper'
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography component="h1" variant="h4">
                    Sign in
                  </Typography>
                  <form
                    noValidate
                    onKeyPress={(e) => hanldeKeyPress(e)}
                    style={{
                      width: "100%", // Fix IE 11 issue.
                      marginTop: 8,
                    }}
                  >
                    <TextField
                      autoComplete="email"
                      autoFocus
                      fullWidth
                      id="email"
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onChange={handleEmailChange}
                      required
                      value={email}
                      variant="outlined"
                    />
                    <TextField
                      autoComplete="current-password"
                      fullWidth
                      id="password"
                      label="Password"
                      margin="normal"
                      name="password"
                      onChange={handlePasswordChange}
                      required
                      type="password"
                      value={password}
                      variant="outlined"
                    />
                    {showReset ? (
                      <Grid item xs={12}>
                        <Link component={RouterLink} to="/forgotpassword" underline="hover" variant="body2">
                          Forgot password?
                        </Link>
                      </Grid>
                    ) : null}
                    <div style={{ height: "25px" }}>
                      {req.isError ? (
                        req.error.message ? (
                          <Typography
                            sx={{
                              color: "error.main",
                            }}
                            variant="body2"
                          >
                            {req.error.message}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              color: "error.main",
                            }}
                            variant="body2"
                          >
                            Error. Try again
                          </Typography>
                        )
                      ) : null}
                    </div>
                    <div
                      style={{
                        margin: "24px, 0px, 0px, 0px",
                        position: "relative",
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={req.loading}
                        fullWidth
                        onClick={handleLogin}
                        style={{ marginBottom: 10, height: 40, maxHeight: 40, minHeight: 40 }}
                        variant="contained"
                      >
                        Sign in
                      </Button>
                      {req.loading && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: -12,
                            marginLeft: -12,
                          }}
                        />
                      )}
                    </div>
                    <Link component={RouterLink} to="/signup" underline="hover" variant="body2">
                      Don't have an account? Sign Up
                    </Link>
                    {googleUrl !== null ? (
                      <>
                        <Divider style={{ marginTop: 15, marginBottom: 15 }} />

                        <Button
                          disabled={req.loading || googleUrl === null}
                          fullWidth
                          href={googleUrl}
                          style={{ backgroundColor: "#FFF", color: "#000", marginBottom: 10, height: 40, maxHeight: 40, minHeight: 40 }}
                          variant="outlined"
                        >
                          <img src={GoogleMark} style={{ width: "22px", marginRight: 10 }} />
                          Login with Google
                        </Button>
                      </>
                    ) : null}
                  </form>
                </div>
              </Box>

              <div style={{ marginTop: "50px", marginBottom: "15px" }}>
                <Copyright />
              </div>
            </Container>
          </>
        }
        path="/"
      />

      <Route element={<GoogleRedirect setReq={setReq} shouldSignup={shouldSignup} />} path="/auth/google" />
    </Routes>
  );
}

// <Route path='/login/auth/google'>
//   <GoogleRedirect setReq={setReq} shouldSignup={shouldSignup}/>
// </Route>

// <Button
//   startIcon={<Help/>}
//   href="https://partizion.io/guide/stay-logged-in"
//   target='_blank'
//   style={{color: '#333', marginTop: '15px', fontSize: 12, opacity: 0.5}}
// >
//   Getting logged out often? Learn why
// </Button>

// <Button onClick={GoogleAuth}> Google Login </Button>
