import React, { useState, useEffect } from "react";
import useGlobal from "../GlobalState/Store/Store";
import axios from "axios";
import setAuthToken from "../Utils/setAuthToken.js";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";

import CssBaseline from "@mui/material/CssBaseline";
import DetectClientInfo from "../Utils/DetectClientInfo.js";

import { Button, CircularProgress, Link, Grid, Typography, TextField, Paper, Divider } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import Logo from "../Assets/logo@150.png";
import GoogleMark from "../Assets/GoogleMark.png";
import ChromeIcon from "../Assets/CustomSVGS/chromeIcon.svg";
//import { ReactComponent as Logo } from '../Assets/logo@150.png';

function Copyright() {
  return (
    <Typography align="center" color="textSecondary" variant="body2">
      {"Copyright © "}
      <Link color="inherit" href="https://partizion.io/" target="_blank" underline="hover">
        Partizion
      </Link>{" "}
      {new Date().getFullYear()}
      {". "}
      By signing up you are agreeing to our
      <Link color="inherit" href="https://partizion.io/terms" target="_blank" underline="hover">
        {" Privacy Policy"}
      </Link>{" "}
      {" and "}
      <Link color="inherit" href="https://partizion.io/terms" target="_blank" underline="hover">
        Terms of Service
      </Link>{" "}
    </Typography>
  );
}

export default function SignUp() {
  const [globalState, globalActions] = useGlobal();
  let navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["partizion_token", "partizion_refreshToken"]);
  const { browserName, majorVersion, OSName } = DetectClientInfo();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loserfield, setLoserField] = useState(null);
  const [req, setReq] = useState({
    loading: false,
    isError: false,
    error: null,
  });

  const cookieMaxAge = 60 * 60 * 24 * 60; // 60 days in seconds

  const handleLoserChange = (event) => {
    setLoserField(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  //SIGNUP
  var receivedResponse;
  const handleSignup = (event) => {
    setReq({
      loading: true,
      isError: false,
      error: null,
    });
    event.preventDefault();

    const userData = {
      name: name,
      email: email.toLowerCase(),
      password: password,
      browser: browserName,
      operatingSystem: OSName,
      loserfield: loserfield,
    };

    const baseURL = process.env.NODE_ENV !== "development" ? "https://www.api.partizion.io/api/v2" : "http://localhost:5000/api/v2";

    axios({
      method: "post",
      url: `${baseURL}/auth/signup`,
      data: userData,
    })
      .then((res) => {
        //console.log("signup success")
        setReq({
          loading: false,
          isError: false,
          error: null,
        });
        receivedResponse = res.data;
        // set token
        const { partizion_token, partizion_refreshToken } = res.data;
        // eslint-disable-next-line no-undef
        let isProd = process.env.NODE_ENV !== "development" ? true : false;

        setCookie("partizion_refreshToken", partizion_refreshToken, { path: "/", sameSite: "lax", maxAge: cookieMaxAge, secure: isProd });
        setCookie("partizion_token", partizion_token, { path: "/", sameSite: "lax", maxAge: cookieMaxAge, secure: isProd });
        // Set token to Auth header
        setAuthToken(partizion_token);
      })
      .then(() => {
        // console.log("second response handling")
        // console.log(receivedResponse)
        const { partizion_token, partizion_refreshToken } = receivedResponse;
        // console.log(partizion_token + " " + partizion_refreshToken)
        //
        if (partizion_token) {
          // Set current user
          const decoded = jwt_decode(partizion_token);
          globalActions.setUser(decoded);
          globalActions.LogInOut(true);
          globalActions.validatePrivateRoutePrivilege(true);

          navigate("/");
        }
      })
      .catch((err) => {
        //console.log("signup failed")
        setReq({
          loading: false,
          isError: true,
          error: err.response ? err.response.data : { code: 5, message: "Internal Server Error" },
        });
      });
  };

  //GOOGLE AUTH
  const [googleUrl, setGoogleUrl] = useState(null);
  useEffect(() => {
    const baseURL = process.env.NODE_ENV !== "development" ? "https://www.api.partizion.io/api/v2" : "http://localhost:5000/api/v2";

    axios
      .get(`${baseURL}/auth/google/url`)
      .then((res) => {
        //console.log(res)
        setGoogleUrl(res.data.url);
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line react/display-name
    return () => null;
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 64,
      }}
    >
      <Link style={{ position: "absolute", left: 20, top: 20 }} to="/" underline="hover">
        <img src={Logo} style={{ marginBottom: "20px" }} />
      </Link>

      <CssBaseline />
      {globalState && globalState.extensionConnection && globalState.extensionConnection.isConnected ? null : (
        <Paper
          elevation={0}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            width: "90%",
            maxWidth: 600,
            backgroundColor: "rgba(61, 90, 254, 0.20)",
            color: "#FFF",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 70,
            padding: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 15 }}>
            <InfoOutlinedIcon style={{ color: "#3D5AFE", marginRight: "15px" }} />
            <Typography style={{ color: "rgba(61, 90, 254, 0.85)" }} variant="body2">
              {" "}
              Looks like you haven't installed the Partizion extension yet. Install the extension so you can view and save tabs from your dashboard.
            </Typography>
          </div>
          <Button
            color="primary"
            href="https://chrome.google.com/webstore/detail/partizion/ldimfpkkjopddckaglpeakpaepclcljn"
            style={{ minWidth: 175 }}
            variant="contained"
          >
            <img src={ChromeIcon} style={{ width: "22px", marginRight: 10 }} />
            ADD TO CHROME
          </Button>
        </Paper>
      )}
      <Paper
        elevation={0}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 48,
          width: 425,
          backgroundColor: "rgba(255, 255, 255, 0)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography component="h1" gutterBottom variant="h4">
            Sign up
          </Typography>
          <Typography align="center" component="h2" variant="body2">
            Partizion helps you organize, find, and manage your work in the browser
          </Typography>
          <form
            noValidate
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: 8,
            }}
          >
            <TextField
              autoComplete="second-name"
              fullWidth
              id="loser"
              label="loser"
              margin="normal"
              name="loser"
              onChange={handleLoserChange}
              required
              style={{ display: "none" }}
              type="loser"
              value={loserfield}
              variant="outlined"
            />
            <TextField
              autoComplete="name"
              autoFocus
              fullWidth
              id="name"
              label="First Name"
              margin="normal"
              name={name}
              onChange={handleNameChange}
              required
              variant="outlined"
            />
            <TextField
              autoComplete="email"
              fullWidth
              id="email"
              label="Email Address"
              margin="normal"
              name="email"
              onChange={handleEmailChange}
              required
              value={email}
              variant="outlined"
            />
            <TextField
              autoComplete="current-password"
              fullWidth
              id="password"
              label="Password"
              margin="normal"
              name="password"
              onChange={handlePasswordChange}
              required
              type="password"
              value={password}
              variant="outlined"
            />
            <div style={{ height: "25px" }}>
              {req.isError ? (
                req.error.message ? (
                  <Typography style={{ color: "#f44336" }} variant="body2">
                    {req.error.message}
                  </Typography>
                ) : (
                  <Typography style={{ color: "#f44336" }} variant="body2">
                    Error. Try again
                  </Typography>
                )
              ) : null}
            </div>
            <div
              style={{
                margin: "24px, 0px, 0px, 0px",
                position: "relative",
              }}
            >
              <Button
                color="primary"
                disabled={req.loading}
                fullWidth
                onClick={handleSignup}
                style={{ height: 40, maxHeight: 40, minHeight: 40 }}
                variant="contained"
              >
                Sign up
              </Button>
              {req.loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </div>
            <Grid alignItems="center" container direction="row" justifyContent="flex-start">
              <Grid item xs={12}>
                <Link component={RouterLink} to="/login" underline="hover" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
            {googleUrl !== null ? (
              <>
                <Divider style={{ marginTop: 15, marginBottom: 15 }} />

                <Button
                  disabled={req.loading || googleUrl === null}
                  fullWidth
                  href={googleUrl}
                  style={{ backgroundColor: "#FFF", color: "#000", marginBottom: 10, height: 40, maxHeight: 40, minHeight: 40 }}
                  variant="outlined"
                >
                  <img src={GoogleMark} style={{ width: "22px", marginRight: 10 }} />
                  Continue with Google
                </Button>
              </>
            ) : null}
          </form>
        </div>
      </Paper>
      <Copyright style={{ marginTop: 50 }} />
    </div>
  );
}
