import React, { useState } from "react";
import useGlobal from "../GlobalState/Store/Store";
import axios from "axios";
import setAuthToken from "../Utils/setAuthToken.js";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";

import CssBaseline from "@mui/material/CssBaseline";

import { Container, Button, CircularProgress, Link, Grid, Typography, TextField, Snackbar } from "@mui/material";

import Alert from "@mui/material/Alert";

import { Link as RouterLink } from "react-router-dom";

import Logo from "../Assets/logo@150.png";

function CustomAlert(props) {
  return <Alert elevation={6} variant="filled" {...props} />;
}

export default function Login(props) {
  const [globalState, globalActions] = useGlobal();
  const [cookies, setCookie, removeCookie] = useCookies(["partizion_token", "partizion_refreshToken"]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [pin, setPin] = useState("");
  const [req, setReq] = useState({
    loading: false,
    isError: false,
    error: null,
  });
  const [pageState, setPageState] = useState("email");

  const baseURL = process.env.NODE_ENV !== "development" ? "https://www.api.partizion.io/api/v2" : "http://localhost:5000/api/v2";

  const handleOpenSessionExpired = () => {
    //setSessionExpired(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleconfirmPasswordChange = (event) => {
    setconfirmPassword(event.target.value);
  };

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const handleReset = (event) => {
    setReq({
      loading: true,
      isError: false,
      error: null,
    });
    event.preventDefault();

    //console.log("Trying to reset password for " + email)

    const userData = {
      email: email,
    };

    axios({
      method: "post",
      url: `${baseURL}/auth/reset-password`,
      data: userData,
    })
      .then((res) => {
        //console.log("login success")
        setReq({
          loading: false,
          isError: false,
          error: null,
        });
        //console.log(res.data)
        // set token
        const { partizion_token, partizion_refreshToken } = res.data;
        // const jwtDecode = jwt_decode(token)
        // const refreshDecode = jwt_decode(refreshToken)
        // const currentTime = Date.now() / 1000;
        // const jwtExpiry = (jwtDecode.exp - currentTime) //seconds
        // const refreshExpiry = (refreshDecode.exp - currentTime) //seconds //for days -> (60*60*24)
        //
        // console.log("jwt is set to expire in: " + jwtExpiry)
        // console.log("refresh is set to expire in: " + refreshExpiry)
        // eslint-disable-next-line no-undef
        let isProd = process.env.NODE_ENV !== "development" ? true : false;
        setCookie("partizion_token", partizion_token, { path: "/", sameSite: "lax", secure: isProd });
        setCookie("partizion_refreshToken", partizion_refreshToken, { path: "/", sameSite: "lax", secure: isProd });
        // Set token to Auth header
        setAuthToken(partizion_token);
        // Decode token to get user data
        if (partizion_token) {
          // Set current user
          const decoded = jwt_decode(partizion_token);
          globalActions.setUser(decoded);
          globalActions.LogInOut(true);
          globalActions.validatePrivateRoutePrivilege(true);
        }
      })
      .catch((err) => {
        //console.log("login failed")
        setReq({
          loading: false,
          isError: true,
          error: err.response ? err.response.data : { code: 5, message: "Internal Server Error" },
        });
      });
  };

  if (req.error != null) {
    console.log(req.error);
  }

  // const hanldeKeyPress = (event) => {
  //   var charCode = event.which || event.charCode || event.keyCode
  //   //console.log("key " + charCode + " pressed")
  //   if(charCode == 13){
  //     //console.log("Enter key pressed")
  //      //if enter is pressed login
  //      handleReset(event)
  //   }
  // }

  function NextPage(pageState) {
    setPageState(pageState);
  }

  const handleRequestPin = (event) => {
    setReq({
      loading: true,
      isError: false,
      error: null,
    });
    event.preventDefault();

    const userData = {
      email: email,
    };

    axios({
      method: "post",
      url: `${baseURL}/auth/request-pin`,
      data: userData,
    })
      .then((res) => {
        //console.log("login success")
        setReq({
          loading: false,
          isError: false,
          error: null,
        });
        setPageState("pin");
      })
      .catch((err) => {
        //console.log("login failed")
        setReq({
          loading: false,
          isError: true,
          error: err.response ? err.response.data : { code: 5, message: "Internal Server Error" },
        });
      });
  };

  const handleVerifyPin = (event) => {
    setReq({
      loading: true,
      isError: false,
      error: null,
    });
    event.preventDefault();

    const userData = {
      email: email,
      pin: pin,
    };

    axios({
      method: "post",
      url: `${baseURL}/auth/verify-pin`,
      data: userData,
    })
      .then((res) => {
        //console.log("login success")
        setReq({
          loading: false,
          isError: false,
          error: null,
        });
        setPageState("changepassword");
      })
      .catch((err) => {
        //console.log("login failed")
        setReq({
          loading: false,
          isError: true,
          error: err.response ? err.response.data : { code: 5, message: "Internal Server Error" },
        });
      });
  };

  const handleChangePassword = (event) => {
    setReq({
      loading: true,
      isError: false,
      error: null,
    });
    event.preventDefault();

    const userData = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    };

    axios({
      method: "post",
      url: `${baseURL}/auth/change-password`,
      data: userData,
    })
      .then((res) => {
        //console.log("login success")
        setReq({
          loading: false,
          isError: false,
          error: null,
        });
        //console.log(res.data)
        // set token
        const { partizion_token, partizion_refreshToken } = res.data;
        // eslint-disable-next-line no-undef
        let isProd = process.env.NODE_ENV !== "development" ? true : false;
        setCookie("partizion_token", partizion_token, { path: "/", sameSite: "lax", secure: isProd });
        setCookie("partizion_refreshToken", partizion_refreshToken, { path: "/", sameSite: "lax", secure: isProd });
        // Set token to Auth header
        setAuthToken(partizion_token);
        // Decode token to get user data
        if (partizion_token) {
          // Set current user
          const decoded = jwt_decode(partizion_token);
          globalActions.setUser(decoded);
        }
      })
      .then(() => {
        //console.log("redirect to dashboard")
        globalActions.LogInOut(true);
        globalActions.validatePrivateRoutePrivilege(true);
      })
      .catch((err) => {
        //console.log("login failed")
        setReq({
          loading: false,
          isError: true,
          error: err.response ? err.response.data : { code: 5, message: "Internal Server Error" },
        });
      });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        position: "relative",
        width: "350px",
        height: "500px",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "32px, 32px, 0px, 32px",
      }}
    >
      <CssBaseline />
      <div
        style={{
          marginTop: 64,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={3500}
          onClose={handleCloseSnackBar}
          open={false}
        >
          <CustomAlert onClose={handleCloseSnackBar} severity="error">
            Your session expired. Please login again
          </CustomAlert>
        </Snackbar>
        <Link to="/" underline="hover">
          <img src={Logo} style={{ marginBottom: "20px" }} />
        </Link>
        <Typography component="h1" style={{ marginBottom: "10px" }} variant="h5">
          {pageState == "pin" ? "Verify Email" : pageState == "changepassword" ? "New password" : "Reset Password"}
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          style={
            pageState != "pin"
              ? { marginBottom: "25px" }
              : { width: "100%", backgroundColor: "rgba(76, 175, 80, 0.35)", borderRadius: "6px", padding: "10px", color: "#333" }
          }
          variant="body2"
        >
          {pageState == "pin"
            ? "Enter the unique pin sent to " + email
            : pageState == "changepassword"
              ? "Now enter a new password for your account"
              : "Enter your account email"}
        </Typography>
        {pageState == "pin" ? (
          //show the password reset form
          <form
            noValidate
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: 8,
            }}
          >
            <TextField fullWidth id="pin" label="Unique pin" margin="normal" name="pin" onChange={handlePinChange} required value={pin} variant="outlined" />
            <div>
              {req.isError ? (
                req.error.message ? (
                  <Typography style={{ color: "#f44336" }} variant="body2">
                    {req.error.message}
                  </Typography>
                ) : (
                  <Typography style={{ color: "#f44336" }} variant="body2">
                    Error. Try again
                  </Typography>
                )
              ) : null}
            </div>
            <div
              style={{
                margin: "24px, 0px, 0px, 0px",
                position: "relative",
              }}
            >
              <Button color="primary" disabled={req.loading} fullWidth onClick={(e) => handleVerifyPin(e)} variant="contained">
                Verify
              </Button>
              {req.loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </div>
          </form>
        ) : pageState == "changepassword" ? (
          <form
            noValidate
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: 8,
            }}
          >
            <TextField
              autoComplete="current-password"
              fullWidth
              id="password"
              label="Password"
              margin="normal"
              name="password"
              onChange={handlePasswordChange}
              required
              type="password"
              value={password}
              variant="outlined"
            />
            <TextField
              autoComplete="current-password"
              fullWidth
              id="confirmPassword"
              label="Re-enter Password"
              margin="normal"
              name="confirmPassword"
              onChange={handleconfirmPasswordChange}
              required
              type="password"
              value={confirmPassword}
              variant="outlined"
            />
            <div style={{ height: "35px" }}>
              {req.isError ? (
                req.error.message ? (
                  <Typography style={{ color: "#f44336" }} variant="body2">
                    {req.error.message}
                  </Typography>
                ) : (
                  <Typography style={{ color: "#f44336" }} variant="body2">
                    Error. Try again
                  </Typography>
                )
              ) : null}
            </div>
            <div
              style={{
                margin: "24px, 0px, 0px, 0px",
                position: "relative",
              }}
            >
              <Button color="primary" disabled={req.loading} fullWidth onClick={(e) => handleChangePassword(e)} variant="contained">
                Change Password
              </Button>
              {req.loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </div>
          </form>
        ) : (
          //show enter email page
          <form
            noValidate
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: 8,
            }}
          >
            <TextField
              autoComplete="email"
              autoFocus
              fullWidth
              id="email"
              label="Email Address"
              margin="normal"
              name="email"
              onChange={handleEmailChange}
              required
              value={email}
              variant="outlined"
            />
            <div style={{ height: "35px" }}>
              {req.isError ? (
                req.error.message ? (
                  <Typography style={{ color: "#f44336" }} variant="body2">
                    {req.error.message}
                  </Typography>
                ) : (
                  <Typography style={{ color: "#f44336" }} variant="body2">
                    Error. Try again
                  </Typography>
                )
              ) : null}
            </div>
            <div
              style={{
                margin: "24px, 0px, 0px, 0px",
                position: "relative",
              }}
            >
              <Button color="primary" disabled={req.loading} fullWidth onClick={(e) => handleRequestPin(e)} variant="contained">
                Reset Password
              </Button>
              {req.loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </div>
            <Grid container>
              <Grid item xs={12}>
                <Typography align="center" color="textSecondary" style={{ marginTop: 12 }} variant="body2">
                  {"Or go back to the "}
                  <Link color="primary" component={RouterLink} to="/login" underline="hover" variant="body2">
                    login page
                  </Link>
                </Typography>
                <Typography align="center" color="textSecondary" style={{ marginTop: 20 }} variant="body2">
                  Having troubles resetting your password?
                  <Link href="mailto:support@partizion.io?subject=Password Reset" target="_blank" underline="hover">
                    Contact Support
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </Container>
  );
}

// <Grid item xs={12} style={{marginBottom: '10px'}}>
//   <Link href="#" variant="body2">
//     Forgot password?
//   </Link>
// </Grid>
