import React from "react";
import axios from "axios";
import { DefaultMenu } from "./DefaultMenu.js";

import { Box, ListItemIcon, MenuItem, Typography, Divider, Link, ListItemText } from "@mui/material";
import useGlobal from "../../GlobalState/Store/Store.js";

import { Link as RouterLink } from "react-router-dom";

import { useCookies } from "react-cookie";
import setAuthToken from "../../Utils/setAuthToken.js";

import SettingsRounded from "@mui/icons-material/SettingsRounded.js";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { WarningRounded } from "@mui/icons-material";

import useCustomerPortal from "../../Hooks/useCustomerPortal.js";

export default function AccountMenu(props) {
  const handleClose = props.handleClose;
  const handleOpenSettingsDialog = props.handleOpenSettingsDialog;
  const anchorEl = props.anchor;

  const [globalState, globalActions] = useGlobal();
  const [cookies, setCookie, removeCookie] = useCookies(["partizion_token", "partizion_refreshToken"]);
  //const editUrl = globalState.workspaces[activeWorkspace] != undefined ? `/workspace/${paramCase(globalState.workspaces[activeWorkspace].name)}/${paramCase(collection.collectionName)}` : null
  function Logout() {
    const baseURL = process.env.NODE_ENV !== "development" ? "https://www.api.partizion.io/api/v2" : "http://localhost:5000/api/v2";

    axios({
      method: "post",
      url: `${baseURL}/auth/logout`,
      withCredentials: true,
    })
      .then(() => {
        globalActions.setUser({});
        globalActions.LogInOut(false);
        globalActions.validatePrivateRoutePrivilege(false);
        setAuthToken();
        removeCookie("partizion_token", { path: "/" });
        removeCookie("partizion_extension_token", { path: "/" });
        removeCookie("partizion_refreshToken", { path: "/" });
        localStorage.clear();
      })
      .catch((err) => {
        console.log("Error logging out:", err);
        // Still clear everything on the client side even if the server request fails
        globalActions.setUser({});
        globalActions.LogInOut(false);
        globalActions.validatePrivateRoutePrivilege(false);
        setAuthToken();
        removeCookie("partizion_token", { path: "/" });
        removeCookie("partizion_extension_token", { path: "/" });
        removeCookie("partizion_refreshToken", { path: "/" });
        localStorage.clear();
      });
  }

  var subscription =
    globalState.userTrial.subscription || (globalState.user.stripeSubscription && globalState.user.stripeSubscription.status === "active") ? true : false;

  const openPricingTracking = (from) => {
    RegisterAppView(from);
    handleClose();
  };

  function RegisterAppView(from) {
    const data = {
      type: "view",
      from: from,
    };
    axios
      .post("/analytics/webapp", data)
      .then(() => {
        //console.log(res)
      })
      .catch(() => {
        //console.log(err)
      });
  }

  const openSettings = () => {
    handleOpenSettingsDialog();
    handleClose();
  };

  const { GoToCustomerPortal, customerPortalStatus, cancelPending, cancelsAt } = useCustomerPortal();

  //transformOrigin={{ horizontal: 190, vertical: subscription ? -90 : -120 }}
  return (
    <div>
      <DefaultMenu
        MenuListProps={{ dense: true }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        style={{ maxWidth: 325 }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <div
          style={{
            margin: 16,
            minWidth: "200px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "5px" }}>
            {subscription || (globalState.userTrial.trialing && !globalState.userTrial.betaUser) ? (
              <div
                style={{
                  backgroundColor: "#CAD1FB",
                  padding: "4px",
                  borderRadius: "2px",
                  height: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography style={{ color: "#3D5AFE", fontSize: 11 }} variant="caption">
                  {" "}
                  PRO{" "}
                </Typography>
              </div>
            ) : (
              <>
                <div
                  style={{
                    backgroundColor: "#EDEDED",
                    padding: "4px",
                    borderRadius: "2px",
                    height: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ color: "#6E6E6E", fontSize: 11 }} variant="caption">
                    {" "}
                    BASIC{" "}
                  </Typography>
                </div>
              </>
            )}
          </div>
          <Typography variant="body1"> {globalState.user.name} </Typography>
          <Typography style={{ color: "#6E6E6E", fontSize: 14 }} variant="body1">
            {" "}
            {globalState.user.email}{" "}
          </Typography>
          {subscription ? null : !globalState.userTrial.betaUser ? null : (
            <div
              style={{
                backgroundColor: "#EDEDED",
                padding: "8px",
                borderRadius: "2px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                marginTop: "10px",
              }}
            >
              <Typography style={{ color: "#6E6E6E" }} variant="caption">
                {" "}
                You are on the basic plan.{" "}
              </Typography>
              <Typography variant="caption">
                <Link component={RouterLink} fontSize="inherit" onClick={() => RegisterAppView("Acc menu learn more")} to="/pricing?" underline="hover">
                  Learn more about the PRO plan
                </Link>
              </Typography>
            </div>
          )}
        </div>
        {cancelPending && (
          <MenuItem
            // component={RouterLink}
            disabled={customerPortalStatus !== "none"}
            // to="/pricing"
            onClick={() => {
              openPricingTracking("Acc menu cancel pending menuitem");
              GoToCustomerPortal();
            }}
          >
            <ListItemIcon>
              <WarningRounded fontSize="small" style={{ color: "#FF9800" }} />
            </ListItemIcon>
            <Box sx={{ display: "flex", flexDirection: "column", wordBreak: "break-word", overflow: "hidden", flexWrap: "wrap" }}>
              <Typography sx={{ wordBreak: "break-word", color: "text.secondary", fontSize: 12, marginLeft: 0.5, color: "#F50057" }}>
                You canceled your subscription
              </Typography>
              <Typography sx={{ wordBreak: "break-word", color: "text.secondary", fontSize: 12, marginLeft: 0.5 }}>You have access until</Typography>
              <Typography sx={{ wordBreak: "break-word", color: "text.secondary", fontSize: 12, marginLeft: 0.5 }}>{cancelsAt}.</Typography>
            </Box>
          </MenuItem>
        )}
        {globalState.userTrial.trialing && !subscription && (
          <MenuItem component={RouterLink} onClick={() => openPricingTracking("Acc menu trial left menu item")} to="/pricing">
            <ListItemIcon>
              <AutoAwesomeRoundedIcon fontSize="small" style={{ color: "#FF9800" }} />
            </ListItemIcon>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              Your trial{" "}
              <Typography sx={{ color: "text.secondary", fontSize: 12, marginLeft: 0.5 }}>
                {" "}
                — you have {Math.floor(globalState.userTrial.trialEndsDays)} days left
              </Typography>
            </Box>
          </MenuItem>
        )}
        <Divider style={{ marginBottom: "8px" }} />
        <MenuItem
          //component={RouterLink}
          onClick={openSettings}
        >
          <ListItemIcon>
            <SettingsRounded fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>

        <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
        <MenuItem onClick={Logout} style={{ color: "#E91E43" }}>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" style={{ color: "#E91E43" }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </DefaultMenu>
    </div>
  );
}

{
  /* <a
data-show-count="false"
data-text="is great for tab management. Partizion makes organizing and finding my work in the browser effortless!"
href="https://twitter.com/intent/tweet?screen_name=partizion&ref_src=twsrc%5Etfw"
rel="noreferrer"
style={{textDecoration: 'none',}}
target='_blank'
>
<MenuItem onClick={handleClose} >
    <Icon classes={{root: { textAlign: 'center', marginRight: 8}}}>
        <img
            src={Twitter}
            style={{ height: '100%', width: 20}}
        />
    </Icon>
    <ListItemText sx={{color: 'text.primary'}}>
        {' '}
        Show love on twitter
        {' '}
    </ListItemText> 
</MenuItem>
</a> */
}
