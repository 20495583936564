import { useEffect } from "react";
import useGlobal from "../GlobalState/Store/Store";
import axios from "axios";
import setAuthToken from "../Utils/setAuthToken.js";
//import LaunchGoogleAuth from "../Authentication/OAuth.js";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";

import DetectClientInfo from "../Utils/DetectClientInfo.js";

import { useNavigate, useLocation } from "react-router-dom";

import * as queryString from "query-string";

export default function GoogleRedirect() {
  const [globalState, globalActions] = useGlobal();
  let navigate = useNavigate();
  let location = useLocation();
  const { browserName, majorVersion, OSName } = DetectClientInfo();
  const [cookies, setCookie, removeCookie] = useCookies(["partizion_token", "partizion_refreshToken"]);
  const cookieMaxAge = 60 * 60 * 24 * 60; // 60 days in seconds
  //const setReq = props.setReq
  //const shouldSignup = props.shouldSignup
  // let { from } = history.location && history.location !== '/login-redirect' && history.location.state ? {from: {pathname: history.location.state.referrer}} : { from: { pathname: "/" } };
  let { from } =
    location && location !== "/login-redirect" && location.state ? { from: { pathname: history.location.state.referrer } } : { from: { pathname: "/" } };
  //TODO

  //console.log({from})

  useEffect(() => {
    //console.log(location)
    //const paramString = responseUrl.split('?')[1]
    //console.log("using string: " + paramString)
    const urlParams = queryString.parse(location.search);
    if (urlParams.error || !urlParams.code) {
      console.log(`An error occurred getting authenticating with google`);
      //redirect to login
      // setReq({
      //   loading: false,
      //   isError: true,
      //   error: "An error occurred getting code from url Params"
      // })
    } else {
      //console.log(`The code is: ${urlParams.code}`);
      SendCodetoServer(urlParams.code);
    }
  }, []);
  //This page is redirected to and takes the code from the url to send to the server
  function SendCodetoServer(code) {
    //console.log("sending code to server")
    const baseURL = process.env.NODE_ENV !== "development" ? "https://www.api.partizion.io/api/v2" : "http://localhost:5000/api/v2";

    axios({
      method: "post",
      url: `${baseURL}/auth/google/authenticate`,
      data: {
        code: code,
        shouldSignup: true,
        browser: browserName,
        appType: "Web app",
        operatingSystem: OSName,
      },
    })
      .then((res) => {
        //console.log("login success")
        //console.log("RECEIVED " + res.status)
        if (res.status === 200) {
          // setReq({
          //   loading: false,
          //   isError: false,
          //   error: null
          // })
          //console.log(res.data)
          const { partizion_token, partizion_refreshToken } = res.data;

          if (!partizion_token) {
            console.error("No token received from server");
            navigate("/login");
            return;
          }

          let isProd = process.env.NODE_ENV !== "development" ? true : false;
          setCookie("partizion_refreshToken", partizion_refreshToken, { path: "/", sameSite: "lax", maxAge: cookieMaxAge, secure: isProd });
          setCookie("partizion_token", partizion_token, { path: "/", sameSite: "lax", maxAge: cookieMaxAge, secure: isProd });

          setAuthToken(partizion_token);

          try {
            const decoded = jwt_decode(partizion_token);
            globalActions.setUser(decoded);
            globalActions.LogInOut(true);
            globalActions.validatePrivateRoutePrivilege(true);

            navigate("/");
          } catch (error) {
            console.error("Error decoding token:", error);
            navigate("/login");
          }
        } else {
          // setReq({
          //   loading: false,
          //   isError: true,
          //   error: "No Account with that google email"
          // })
          console.log("Error: Google Authentication failed. unrecognized status");
          navigate(from, { replace: true });
        }
      })
      .catch((err) => {
        //console.log("REDIRECT ERROR")
        //console.log(err)
        // setReq({
        //   loading: false,
        //   isError: true,
        //   error: "Attempt to login with google failed. Please try again."
        // })
        console.error("Google Authentication failed:", err);
        navigate("/login");
      });
  }

  return null;
}

// <Button onClick={GoogleAuth}> Google Login </Button>
